import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import * as s from '../../pages/news.module.scss'

const ImpotozameshenieSignal: React.FC = () => {
  return (
    <>
      <SEO
        title="Импортоопережение Импортозамещение авторизации Autodesk в сервисах SIGNAL"
        description="SIGNAL импортозаместил авторизацию Autodesk и разработал собственный сервис для администрирования своих продуктов"
      />
      <NewsSection
        title="Курс на импортоопережение"
        dateData={{
          date: '19.09.2023',
        }}
        maxContent
        rayContent
      >
        <p className={s.text}>
          SIGNAL с начала своего основания продвигал идею цифровизации в массы,
          использования BIM (ТИМ) моделей, как основание для цифрового контроля
          и отчетности строительных объектов. В основе наших web-решений была
          платформа Autodesk и соответственно доступ к нашим продуктам был через
          учетную запись Autodesk. После ухода Autodesk мы сделали упор на
          создание хаба со своим администрированием. Первым делом мы
          импортозаместили INSPECTION, создали свой СОД SIGNAL DOCS (ранее для
          этого использовали АСС или BIM360)
        </p>
        <p className={s.text}>
          И вот сегодня, мы отвязали от Autodesk последний продукт, SIGNAL
          DASHBOARD, и таким образом мы можем заявить, что SIGNAL полностью
          импортозаместился.
        </p>
        <p className={s.text}>
          Теперь доступ к сервисам SIGNAL осуществляется через HUB SIGNAL.
        </p>
        <div className="flex">
          <StaticImage
            className="mx-auto my-4"
            src="../../assets/images/news/impotozameshenie-signal/image-1.png"
            alt="Главная страница SIGNAL HUB"
            title="Главная страница SIGNAL HUB"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Здесь же и происходит глобальное администрирование платформой: Доступ
          к сервисам, создание и настройка проектов, подключение к ним модулей,
          добавление пользователей и ролей, предоставление участникам проектов
          поверхностных прав на уровне администратора, с полными правами или
          участника.
        </p>
        <div className="flex">
          <StaticImage
            className="mx-auto my-4"
            src="../../assets/images/news/impotozameshenie-signal/image-2.png"
            alt="Меню доступа SIGNAL HUB"
            title="Меню доступа SIGNAL HUB"
            placeholder="blurred"
          />
        </div>
        <div className={s.columnWrapper}>
          <div>
            <p className={s.text}>
              Также все наши сервисы имеют мобильную адаптацию, и HUB SIGNAL не
              исключение, 100% функционала и кнопок доступно со смартфона. Таким
              образом, подключать сервисы, создавать новые проекты, настраивать
              права можно прямо по дороге на работу.
            </p>
            <p className={s.text}>
              SIGNAL VALUE остается привязанным к BIM 360, так как является
              сервисом адаптирующим BIM 360 под специфику работы в России.
              Дополнительно основной функционал VALUE, такой как Backup данных,
              ЭЦП и QR-коды будет добавлен в SIGNAL DOCS
            </p>
          </div>
          <div style={{ width: '80%' }} className="flex">
            <StaticImage
              className="mx-auto"
              src="../../assets/images/news/impotozameshenie-signal/image-3.png"
              alt="Мобильная версия админки в SIGNAL HUB"
              title="Мобильная версия админки в SIGNAL HUB"
              placeholder="blurred"
            />
          </div>
        </div>
      </NewsSection>
    </>
  )
}

export default ImpotozameshenieSignal
